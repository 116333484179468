var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BModal', {
    attrs: {
      "id": "modal-result-recalculate-price-class-booking-".concat(_vm.modalId),
      "title": "Tính giá",
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "header-class": "px-1 py-50",
      "body-class": "px-50",
      "centered": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "show": _vm.handleShowModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('BButton', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "center rounded-pill px-2 mr-1",
          attrs: {
            "size": "md",
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.back')) + " ")]), _c('BButton', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-gradient px-2 px-md-3",
          attrs: {
            "pill": "",
            "disabled": _vm.isEmpty(_vm.arrIndexTripSelectSort)
          },
          on: {
            "click": _vm.submitRecalculatePrice
          }
        }, [_c('span', {
          staticClass: "align-middle"
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.recalculatePrice.title')) + " ")])])];
      }
    }])
  }, [_c('BCard', {
    attrs: {
      "body-class": "py-1 px-50 px-md-1"
    }
  }, [_c('div', {
    staticClass: "fw-700 font-medium-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.combinatePrice')) + " ")]), _c('BFormRadioGroup', {
    attrs: {
      "id": "calc-mode",
      "options": _vm.CALC_MODE_OPTIONS,
      "stacked": ""
    },
    model: {
      value: _vm.calcMode,
      callback: function callback($$v) {
        _vm.calcMode = $$v;
      },
      expression: "calcMode"
    }
  })], 1), _c('BAlert', {
    staticClass: "mb-75 py-50 px-1 text-center",
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_c('span', {
    staticClass: "text-airline font-italic"
  }, [_vm._v(" " + _vm._s(_vm.$t("flight['For Round-trip flights, please select Calculate combined price to get the exact price']")) + " ")])]), _c('BCard', {
    attrs: {
      "body-class": "py-1 px-50 px-md-1"
    }
  }, [_c('div', {
    staticClass: "fw-700 font-medium-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.specialServices.itineraries.title')) + " ")]), _vm.dataTripsToCalcPrice ? _c('div', [_c('BFormCheckboxGroup', {
    attrs: {
      "id": "checkbox-group-trip-calc",
      "stacked": ""
    },
    model: {
      value: _vm.arrIndexTripSelect,
      callback: function callback($$v) {
        _vm.arrIndexTripSelect = $$v;
      },
      expression: "arrIndexTripSelect"
    }
  }, _vm._l(_vm.dataTripsToCalcPrice, function (trip, indexTrip) {
    return _c('div', {
      key: indexTrip,
      staticClass: "d-flex my-75"
    }, [_c('BFormCheckbox', {
      attrs: {
        "value": indexTrip
      }
    }, _vm._l(trip, function (segment, indexSegment) {
      return _c('div', {
        key: indexSegment
      }, [_c('span', {
        staticClass: "text-dark fw-700 pl-md-25"
      }, [_vm._v(" " + _vm._s(_vm.getSortTripBySegment(segment, true)) + " ")])]);
    }), 0)], 1);
  }), 0)], 1) : _vm._e()]), _c('BCard', {
    attrs: {
      "body-class": "py-1 px-50 px-md-1"
    }
  }, [_c('div', {
    staticClass: "fw-700 font-medium-1 mb-25"
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.numPassenger')) + " ")]), _c('BRow', {
    staticClass: "justify-content-start"
  }, _vm._l(_vm.countPaxs, function (pax, indexPax) {
    return _c('BCol', {
      key: indexPax,
      attrs: {
        "cols": "auto"
      }
    }, [pax.count > 0 ? _c('span', {
      class: indexPax !== _vm.countPaxs.length - 1 ? 'mr-1' : ''
    }, [_c('span', {
      staticClass: "font-small-4"
    }, [_vm._v(_vm._s(pax.title) + ": ")]), _c('span', {
      staticClass: "text-danger fw-700 font-medium-3"
    }, [_vm._v(_vm._s(pax.count))])]) : _vm._e()]);
  }), 1)], 1), ['1G'].includes(_vm.airlineSearch) ? _c('b-card', {
    attrs: {
      "body-class": "py-75 py-lg-1"
    }
  }, [_c('div', {
    staticClass: "margin-checkbox-label d-flex-center justify-content-around gap-2 text-center"
  }, [_c('b-form-checkbox', {
    staticClass: "custom-control-success",
    attrs: {
      "name": "retain-checkbox"
    },
    model: {
      value: _vm.isBargainFinder,
      callback: function callback($$v) {
        _vm.isBargainFinder = $$v;
      },
      expression: "isBargainFinder"
    }
  }, [_c('span', {
    staticClass: "font-medium-1 font-weight-bolder",
    class: _vm.isBargainFinder ? 'text-success' : 'text-dark'
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation.bargainFinder')) + " ")])])], 1)]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }